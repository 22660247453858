.MuiGrid-container.create-snapshot-grid {
    margin: 0 10px 0 0;
    padding: 5px 0;
    border: 1px #34495e solid;
    border-radius: 7px;
}
.general-export .MuiGrid-item,
.create-snapshot-grid .MuiGrid-item {
    text-align: center;
}
.general-export .MuiGrid-item .content-copy {
    margin-right: 10px;
}