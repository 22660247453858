.MuiFormControl-root.link-search .MuiOutlinedInput-root fieldset{
  border-color: #73869b;
  padding: 0;
}
.bookmark-search {
    color: #6a707e;
    text-align: right;
}

.bookmark-search input {
    position: relative;
    transition: width 0.6s, border-radius 0.6s, background 0.6s, box-shadow 0.6s;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    background: none;
}

.bookmark-search input + .search-icon {
    color: white;
  }
  .bookmark-search input:hover {
    color: white;
    background: #c8c8c8;
    box-shadow: 0 0 0 5px #3d4752;
  }
  .bookmark-search input:hover + .search-icon {
    color: white;
  }
.bookmark-search input:focus {
    transition: width 0.6s cubic-bezier(0, 1.22, 0.66, 1.39), border-radius 0.6s, background 0.6s;
    border: none;
    outline: none;
    box-shadow: none;
    padding-left: 15px;
    cursor: text;
    width: 250px;
    border-radius: auto;
    background: #ebebeb;
    color: black;
    border-radius: 4px;
  }
  .bookmark-search input:focus + .search-icon {
    color: black;
  }
  .bookmark-search input:not(:focus) {
    text-indent: -5000px;
  }

.search-icon {
    position: relative;
    left: -33px;
    top: 8px;
    cursor: pointer;
}
.MuiAutocomplete-listbox {
  background-color: green;
}
.MuiAutocomplete-groupUl{
  background-color: green
}