
#component-error-text, #import-error-text {
    color: red;
    font-weight: bold;
}
.MuiFab-sizeSmall.modification-icon {
    margin-right: 10px;
}
.groups {
    padding-bottom: 20px;
    padding-right: 20px;

}
