.MuiSpeedDial-root .MuiFab-secondary.MuiSpeedDial-fab {
    background-color: #3ddb86;
    color: #fff;
}
#LinkModificationMenu-actions .Mui-error {
    background-color: rgb(244,67,54);
    color: #fff;
}
#LinkModificationMenu-actions .fab-donate {
    background-color: #f39c12;
    color: #fff;
}