.MuiGrid-root.MuiGrid-item.group-container {
    padding-top: 12px;
}
.MuiList-root .MuiListItemSecondaryAction-root {
    right: 50px;
}

.MuiList-root .list-item-highlight {
    margin: 4px 0px 0px 0px;
    padding: 5px 10px;
    background-color: #0f1929;
    color: #bdbdbd;
    
}
.MuiList-root .list-item-new svg{
    margin: 5px auto;
    color: #233547;
}
.MuiList-root .MuiListItemText-multiline {
    overflow: hidden;
    
}

.MuiList-root .MuiListItemText-secondary {
    color: #34495e;
    font-size: .8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;    
}
.MuiListItemSecondaryAction-root {
    color: #34495e;
}
.group-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.group-header:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #34495e;
    margin: auto 0 auto 10px;

}