
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0b121e;
  color: white;
}

.MuiPaper-root.MuiDialog-paper {
  background-color: #0b121e;
}
.MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-color: #34495e;
  margin: 5px;
}

textarea {
  background: transparent;
  margin: 0px 5px;
  padding: 8px;
  border-color: #34495e;
  color: white;
  font-size: 16px;
  border-radius: 7px;
  outline-color: #90caf9;
}
textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

textarea:-moz-placeholder { /* Firefox 18- */
  color: rgba(255, 255, 255, 0.7);
}

textarea::-moz-placeholder {  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.7);
}

textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

textarea::placeholder {
  color: rgba(255, 255, 255, 0.7); 
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
