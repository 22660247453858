.five-eye-header {
    margin-bottom: 20px;
}
.branding-content {
    width: 100%;
    background-color: #2f4255;
    min-height: 120px;
    border-bottom: 4px #34495e solid;
}
.brand-icon {
    text-align: center;
}
.brand-icon svg {
    margin-top: 5px;
    width: 90px;
}
.brand-content {
    padding-top: 0px;
}
.brand-content h1,h3 {
    padding: 0;
    margin: 0px 10px;
    font-weight: 500;
}
.brand-content h3 {
    font-weight: 300;
}
.five-eye-menu {
    background-color: #2f4255;
    line-height: 35px;
    min-height: 35px;
}
.five-eye-menu .menu-search {
    text-align: right;
}

.five-eye-menu .menu-storage {
    text-align: right;
    color: #485b6e;
}

.five-eye-menu button.MuiButton-root {
    margin: 0 3px 0 0px;
    color: white;
}
@media (max-width: 450px) {
    .brand-icon svg {
        margin-top: 30px;
        width: 60px;
    }
    .brand-content h1,h3 {
        font-size: 20px;
        margin: 0px 0px 0px 30px;
        font-weight: 500;
    }
}
@media (max-width: 1024px) {
    .brand-content h1,h3 {
        margin: 0px 0px 0px 50px;
    }
}
 